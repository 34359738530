.loading {
    position: relative;
    margin-top: 30px;
}

.selectWrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    gap: 12px;
}

.selectItem {
    width: 200px;
}

.wrap {
    position: relative;
}

.registerAllBtn {
    margin-bottom: 24px;
}
