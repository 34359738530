.listContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    display: flex;
    border: 1px solid;
    border-radius: 7px;
    flex-direction: column;
    color: #666;
    font-size: 13px;
    padding: 5px 10px;
    line-height: 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    filter: opacity(1) !important;
}
.icon.draggable {
    cursor: grab;
}
.icon.draggable:active {
    cursor: grabbing;
}
.mediaDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    max-width: 100%;
    border-radius: 8px;
    max-height: 200px;
    min-height: 200px;
    overflow: hidden;
}

.mediaImage {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
    max-height: 200px;
}

.detailsDiv {
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 10px;
}

.detailRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.uploadedBy {
    font-size: 12px;
    font-weight: 500;
    color: #939393;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 110px;
}

.description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    height: 30px;
}

.headerCardContainer {
    margin: 30px 10px;
    display: flex;
    width: 100%;
    background-color: var(--black-100);
    border-radius: 20px;
}

.headerCard {
    display: flex;
    font-weight: 600;
    cursor: pointer;
    border: unset;
    padding: 10px 0px;
    width: 100%;
    justify-content: center;
    border-radius: 20px;
    color: var(--black-300);
    text-transform: uppercase;
}

.selectedHeaderStudentCard {
    background-color: var(--primary-400-base);
    color: #ffffff !important;
}

.headerCardStudentMarginRight {
    margin-right: 20px;
}

.headerCardStudentMarginLeft {
    margin-left: 20px;
}

.headerCardValueStudent {
    font-size: 40px;
    color: #0f4880;
    background-color: #e9f4ff;
    border-radius: 5px;
    width: 164px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerCardStatValue {
    color: var(--primary-400-base);
}

.selectedHeaderCardStatValue {
    color: var(--primary-200);
}

.paddingBottom {
    padding-bottom: 20px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 10px;
    padding-bottom: 20px;
}

.addButton {
    width: 140px;
    height: 40px !important;
    justify-content: center;
    font-size: 16px;
}

.formGroup {
    margin-bottom: 32px;
}

.formGroupHalf {
    width: 48%;
}

.formGroup2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerWrap {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
}

.borderTop {
    border-top: 2px solid;
    border-color: #1e90ff;
    padding-top: 40px;
    margin-top: 40px;
}

.footerInfoWrap {
    margin-right: 20px;
}

.footerInfo {
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 7px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #939393;
}

.actionDetailWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.cancelBtn {
    margin-right: 40px;
}

.previewImageContainer {
    width: 30vh;
    height: 30vh;
    margin: 0px 15px 15px 0;
}

.dropzoneItem {
    margin-right: 10px;
    padding: 20px;
    outline: none;
    border: 1px dashed #cfcfcf;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.dropzone__text {
    font-size: 16px;
    color: #212121;
}

.dropzone__btn {
    margin: 0 auto;
}

.icon {
    fill: var(--primary-200);
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-right: 5px;
}

.iconType {
    fill: var(--primary-200);
    width: 80px;
    height: 80px;
}

.iconUpload {
    width: 32px;
    height: 32px;
}

.formGroup2Div {
    width: 48%;
}

.iconPlus {
    height: 24px;
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.menuActionWrap {
    padding: 17px;
}

.menuActionItem {
    display: flex;
    align-items: center;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionTitle {
    margin-left: 11px;
}

.listingTypeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.listingIcon {
    margin: 5px;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.listingIconSelected {
    background-color: #e9f4ff;
}

.radioGroup {
    display: flex;
    border: 1px solid #1e90ff;
    border-radius: 6px;
}

.radioItem {
    padding: 10px !important;
    width: 100%;
    margin: 0px !important;
    font-weight: bold;
}

.radioItemBorderRight {
    border-right: 1px solid #1e90ff;
}

.videoImageThumbnail {
    display: none;
}

.emptyData {
    text-align: center;
}

.mediaPopupContainer {
    width: 95%;
    height: 100%;
}

.mediaPopupContainer > img {
    width: 100%;
    height: 100%;
    max-height: 76vh;
    object-fit: contain;
}

.mediaPopupContainer > div > iframe {
    max-height: 76vh;
}

.mediaDiv > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.mainPreviewContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
}

.mediaPreviewContainer {
    max-width: 30vh;
    height: 30vh;
    display: flex;
    background-color: #e9f4ff;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    border: 1px dashed #cfcfcf;
}

.mediaPreviewContainer > div {
    width: unset !important;
    height: unset !important;
}

.mediaPreviewContainer > div > iframe {
    max-height: 50vh;
}

.mediaItemPDFContainer {
    display: block;
}

.h100 {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.listingMediaPreviewContainer,
.listingMediaPreviewContainer > div {
    width: 100%;
    height: 100%;
}

.listingMediaPreviewContainer > div > iframe,
.listingMediaPreviewContainerDetailPage > div > iframe {
    height: 100% !important;
}

.listingMediaPreviewContainerDetailPage,
.listingMediaPreviewContainerDetailPage > div {
    width: 100% !important;
    height: 100% !important;
}

.mediaPreviewClose {
    position: absolute;
    bottom: 30px;
    right: 15px;
    width: 60px;
    height: 60px;
    z-index: 9;
    background-color: #fff;
    border-radius: 50%;
}

.mediaBlockViewListActionsContainer {
    display: flex;
    width: 100px;
    min-width: 100px;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    align-self: flex-start;
}

.listTitle {
    cursor: pointer;
    color: var(--primary-200);
}

.actionIconsContainer {
    text-align: right;
}

.fileCardHandler {
    display: flex;
    margin-bottom: 32px;
}

.uploadCardHeadingLabel {
    margin-bottom: 15px;
}

.cardDiv {
    opacity: 1 !important;
    padding: 10px;
}

.contentWrapper {
    position: relative;
}

.spinnerOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 5;
    pointer-events: all;
}
