.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 32px;
    padding: 2px 12px;
    margin-bottom: 8px;
}

.title {
    color: #1f1f1f;
    font-size: 16px;
    margin: 0;
}

.actions {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actionBtn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: none !important;
}

.actionBtn:hover {
    background: #f1f3f4 !important;
}

.actionBtn__add {
    border-radius: 50%;
}

.icon {
    width: 16px;
    height: 16px;
}

.iconRotate {
    transform: rotate(180deg);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 12px;
}

.itemInfo {
    display: flex;
    align-items: center;
    gap: 6px;
}

.itemLogo {
    max-width: 24px;
}

.itemLabel {
    flex: 1;
}
