.statsTitle {
    margin-top: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.statsValue {
    margin-top: 8px;
    font-family: Poppins;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.statsIcon {
    width: 15px;
    height: 15px;
}
.stats {
    display: flex;
    gap: 16px;
    margin-top: 20px;
    margin-bottom: 44px;
    position: relative;
    justify-content: space-between;
    flex-direction: row;
}

.statsBox {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    padding: 10px 20px 10px 20px;
}

.loadingWrap {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
