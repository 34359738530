.wrap {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
}

.title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 16px;
}

.subTitle {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 16px;
}

.programNames {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 0;
}

.body {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
}

.chartActive {
    aspect-ratio: 1;
    position: relative;
    background: #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart {
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.rightInfo {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 4px;
}

.infoItem {
    display: flex;
    padding: 0 4px;
    cursor: pointer;
    align-items: center;
    border: solid 1px transparent;
    justify-content: space-between;
    gap: 8px;
}
.infoItemSelected {
    border-radius: 6px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--black-100);
}

.infoItem__left {
    display: flex;
    align-items: center;
    gap: 4px;
}

.infoItem__color {
    min-width: 16px;
    width: 16px;
    height: 16px;
    border-radius: 24px;
    background-color: var(--black-100);
}

.infoItem__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
}

.infoItem__value {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--primary-400-base);
}

@media (min-width: 1600px) {
    .chart {
        width: 200px;
    }
}
