.wrap {
    position: absolute;
    z-index: 1;
}

.actionItem {
    display: flex;
    border: solid 1px #1e90ff;
    background-color: #e9f4ff;
    border-radius: 4px;
    padding: 1px;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.commentInput {
    background: transparent;
    border: none;
    padding: 4px;
    margin: 4px;
}

.remarkCommentWrap {
    position: relative;
}

.remarkIndicator {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 8px;
    background-color: #ffe55b;
    cursor: pointer;
}

.remarkIndicatorIcon {
    width: 12px;
    height: 12px;
}

.signedItem {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.date {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
