.headerWrap {
    display: flex;
    margin-bottom: 18px;
    position: relative;
}

.mb0 {
    margin-bottom: 0;
}

.headerWrapBody {
    flex: 1;
    display: flex;
}

.headerIconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 12px;
    background-color: #1e90ff;
    margin-right: 32px;
}

.headerIcon {
    width: 40px;
    height: 40px;
}

.headerInfoWrap {
    margin-right: 32px;
}

.headerInfo__userType {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #212121;
    margin-bottom: 2px;
}

.headerInfo__label {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #666666;
    margin-bottom: 0;
}

.roleInfo {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #666666;
    padding-left: 4px;
}

.headerIndexWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: solid 1px #1e90ff;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 16px;
}

.header__index {
    font-size: 24px;
    font-weight: bold;
    color: #1e90ff;
    margin-bottom: 0;
}

.header__primary {
    height: 32px;
    padding: 4px 15px;
    border-radius: 8px;
    background-color: #1e90ff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #ffffff;
    margin-bottom: 0;
    text-transform: uppercase;
}

.header__nonPrimary {
    background-color: #939393;
}

.divider {
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.menuWrap {
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.menuActionWrap {
    padding: 17px;
}

.menuActionItem {
    display: flex;
    align-items: center;
    padding: 8px 30px !important;
}

.menuActionItem:hover {
    background-color: #e9f4ff !important;
}

.menuActionIcon {
    height: 24px;
    width: 24px;
}

.moreIcon {
    height: 18px;
}

.menuActionTitle {
    margin-left: 16px;
}

.instructorType {
    font-size: 16;
    line-height: 1.33;
    color: #666666;
    margin-left: 12px;
}
