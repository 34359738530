.eventContent {
    font-size: 12px;
    display: block;
    margin-left: 8px;
    width: 100%;
    height: 100%;
    line-height: 1;
    padding-right: 3px;
    cursor: pointer;
}

.eventTitle {
    font-weight: 500;
    margin-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 9px;
}

.eventTime {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 3px 0 0 0;
    padding-right: 9px;
}

.eventMonthView {
    display: flex;
    align-items: center;
    color: var(--black-300);
    padding: 0px 3px;
    width: 100%;
}

.eventTitleMonthView {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.eventTitleMonthView > strong {
    margin-right: 5px;
}

.dot {
    width: 7px;
    height: 7px;
    border-radius: 4px;
    margin-right: 3px;
}

.bodyWrap {
    display: flex;
}

.rightSide {
    position: relative;
    min-width: 600px;
    flex: 1;
}

.viewOptionsWrap {
    margin-bottom: 30px;
}

.calendarIndicator {
    margin-bottom: 10px;
}

.highlightEventPreviewWrap {
    z-index: 9999 !important;
}

.highlightEventPreview {
    box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%) !important;
}

.eventPreviewWrap {
    border-radius: 6px;
    box-shadow: 4px 8px 32px 0 rgba(48, 49, 51, 0.12);
    border: solid 0.5px var(--primary-400-base);
    background-color: #f5f5f5;
}

.eventPreviewBody {
    padding: 12px 24px;
    max-width: 600px;
}

.eventPreviewHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
}

.eventPreviewHeaderBtn {
    border: none !important;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    border-radius: 50%;
}

.eventPreviewHeaderBtn:hover {
    background-color: #a5d3ff;
}

.eventPreviewHeaderEditIcon {
    width: 25px;
    height: 25px;
}

.eventPreviewHeaderIcon {
    width: 18px;
    height: 18px;
}

.eventPreviewContent {
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
}

.eventPreviewContentRow {
    display: flex;
    margin-bottom: 12px;
}

.eventPreviewContentRow__border {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--black-200);
}

.eventPreviewContentRow:last-child {
    margin-bottom: 0;
}

.eventPreviewContentLeft {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    min-width: 30px;
}

.eventPreviewType {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background: #ccc;
}

.eventPreviewInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 5px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.eventPreviewTitleEvent {
    font-size: 25px;
    font-weight: 500;
    color: var(--black-base);
    margin-bottom: 0;
}

.eventPreviewTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--black-base);
    margin-bottom: 0;
    max-width: 400px;
}

.participantDesc {
    font-size: 13px;
    font-weight: 500;
    color: var(--black-300);
    margin: 0;
}

.eventPreviewDate {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: var(--black-base);
}

.eventPreviewDate__dot {
    margin: 0 6px;
    width: 6px;
    min-width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--primary-400-base);
}

.eventPreviewDate__time {
    font-weight: bold;
}

.tooltipCard {
    padding-top: 0;
    font-size: 11px;
}

.infoIcon {
    width: 20px;
    height: 20px;
    margin-top: 2px;
}

.goingWrap {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--primary-400-base);
    border-bottom: 1px solid var(--primary-400-base);
    padding: 20px 24px;
}

.goingTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
    margin-right: 80px;
}

.goingBody {
    flex: 1;
    justify-content: flex-end;
}

.eventPreviewAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
}

.inviteInfoWrap {
    width: 100%;
}

.inviteInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
    cursor: pointer;
}

.collapseInviteIconWrap {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.iconCollapse {
    transform: rotate(0deg);
}

.participantsWrap {
    margin-top: 10px;
    overflow: auto;
    max-height: 300px;
}

.participantItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
}

.participantItem__avatarWrap {
    position: relative;
}

.participantItem__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.participantItem__inviteStatus {
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 14px;
    height: 14px;
    z-index: 1;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.participantItem__inviteStatusIcon {
    width: 12px;
    height: 12px;
}

.participantItemInfo {
    display: flex;
    flex-direction: column;
}

.participantItem__name {
    font-size: 14px;
    line-height: 16px;
}

.participantItem__owner {
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
}
