.wrap {
    height: 100%;
    background: #ccc;
    position: relative;
    overflow: auto;
}

.headerContainer {
    position: sticky;
    top: 0;
    left: 100px;
    z-index: 10;
    background: #ccc;
}

.headerWrap {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 12px;
    margin: 0 50px 16px;
}

.headerWrapFromEmail {
    max-width: 1100px;
    margin: 0 auto 6px !important;
}

.webFormHeader {
    width: 1100px;
    min-width: 1100px;
    margin: 0 auto 6px !important;
}

.backBtn {
    margin-right: 20px;
}

.backBtnWrap {
    cursor: pointer;
}

.backIcon {
    width: 32px;
    height: 32px;
}

.headerTitleWrap {
    margin-right: 24px;
}

.headerTitle {
    font-size: 20px;
    font-weight: 800;
    color: #212121;
    line-height: 1.41;
    margin-bottom: 4px;
}

.headerTaskType {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #939393;
    margin-bottom: 0;
}

.taskCode {
    font-size: 20px;
    line-height: 40px;
    font-weight: 800;
    color: #1e90ff;
    margin-bottom: 0;
}

.taskInfoWrap {
    border-radius: 12px;
    border: solid 2px #1e90ff;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 8px 24px;
    margin: 0 50px 16px;
}

.taskInfoWrapFromEmail {
    max-width: 1100px;
    margin: 0 auto 6px !important;
}

.taskInfo {
    display: flex;
}

.taskInfoManage {
    margin-right: 24px;
}

.taskInfoManage__title {
    font-size: 16px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 8px;
}

.taskInfoManageAction {
    display: flex;
    align-items: center;
}

.taskInfoManageAction__item {
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #e5e5e5;
    padding: 5px 4px;
    margin-right: 16px;
    cursor: pointer;
}

.resizableItem {
    width: 100%;
    height: 100%;
}

.taskInfoManageAction__item:last-child {
    margin-right: 0;
}

.taskInfoManageAction__item__text {
    font-size: 10px;
    font-weight: bold;
    color: #666666;
    text-transform: uppercase;
}

.taskInfoDetail {
    border-radius: 4px;
    border: solid 1px #1e90ff;
    background-color: #ffffff;
    padding: 8px;
    flex: 1;
}

.taskInfoDetailHeader {
    display: flex;
    flex-wrap: wrap;
}

.taskInfoDetailHeaderItem {
    margin-right: 8px;
    padding: 2px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    min-width: 150px;
}

.taskInfoDetailHeaderItem__text {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 2px;
}

.taskInfoDetailHeaderItem__value {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.33;
    color: #666666;
    margin-bottom: 0;
}

.taskInfoAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

.taskInfoActionItem {
    margin-top: 10px;
    margin-right: 20px;
}

.reopenText {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 2px;
}

.reasonContent {
    white-space: pre-wrap;
}

.body {
    margin: 0 60px;
}

.pageWrap {
    position: relative;
    margin-bottom: 30px;
}

.pageImg {
    margin-bottom: 10px;
    width: 100%;
}

.pageImg:last-child {
    margin-bottom: 0;
}

.lastPage {
    margin-bottom: 0;
}

.resizableBox {
    position: absolute;
    cursor: move;
    z-index: 999;
}

.customHandleResizable {
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 8px;
    height: 8px;
    background-color: #1153aa;
    opacity: 0.75;
    border-radius: 4px;
    cursor: se-resize;
}

.iframeWebForm {
    height: calc(72vh - 4px);
    width: 100%;
}

.remindWrap {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.approvedText {
    color: #18a957;
}

@media (max-width: 1368px) {
    .body {
        margin: 0 20px;
    }

    .headerContainer {
        left: 0;
    }

    .headerWrap {
        margin: 0 8px 12px !important;
    }

    .taskInfoManage {
        margin-right: 10px;
    }

    .taskInfoManage__title {
        font-size: 12px;
        line-height: 14px;
    }

    .taskInfoWrap {
        padding: 4px;
        margin: 0 8px 12px !important;
    }

    .taskInfoManageAction__item {
        width: 40px;
        height: 40px;
        margin-right: 6px;
    }

    .taskInfoManageAction__item__text {
        font-size: 6px;
        line-height: 10px;
    }

    .taskInfoDetailHeaderItem {
        min-width: unset;
        flex: 1;
        margin-bottom: 6px;
    }

    .taskInfoDetailHeaderItem__text {
        font-size: 8px;
        line-height: 10px;
    }

    .taskInfoDetailHeaderItem__value {
        font-size: 10px;
        line-height: 12px;
    }

    .taskInfoActionItem {
        margin-right: 6px;
    }

    .webFormHeader {
        width: auto;
        min-width: auto;
        margin: 0;
    }
}
