.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    border: solid 1px var(--black-100);
    min-height: 100px;
    margin-top: 16px;
}

.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
}

.action {
    display: flex;
    justify-content: flex-end;
}

.checkbox {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
}

.visible {
    text-align: center;
}
