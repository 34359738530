.tableContainer {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 8px;
}

.table {
    width: 100%;
    table-layout: auto;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.table.tableFixed {
    table-layout: fixed;
}

.tableHead {
    background: var(--primary-100);
    border: 2px solid var(--primary-100);
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCell {
    display: table-cell;
    padding: 3px 8px;
    font-size: 13px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 2px solid #c7c7c7;
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
    min-height: 40px;
    height: 40px;
    word-break: normal;
}

.tableCellHeader {
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.5rem;
    position: relative;
    color: var(--black-200);
}

.tableCellHeader.small {
    font-size: 11px;
}

.tableCellBody {
    font-size: 13px;
    font-weight: 600;
    border: 2px solid #c7c7c7;
}

.bodyTable {
    max-height: 500px;
    overflow: auto;
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: top;
    background: white;
}

.tableRow:nth-child(even) {
    background: #fbfbfb;
}

.tableRow:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.dark {
    background-color: var(--black-100);
}

.menuCell {
    position: relative;
}

.cellInput,
.cellInput :global(.ant-input),
.cellInput::placeholder,
.cellInput :global(.ant-input)::placeholder,
.cellInput:focus {
    border: none;
    /*outline: none !important;*/
    background-color: transparent !important;
    /*padding: 0;*/
    font-size: 14px !important;
    font-weight: 400 !important;
    min-height: 44px;
    width: 100%;
    min-width: 80px;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
}

.cellInput:hover,
.cellInput:focus-within {
    /*background-color: rgba(0, 0, 0, 0.07) !important;*/
}

.cellInput:focus {
    border-color: var(--primary-400-base);
}

.cellInput:focus-visible {
    outline: auto ridge var(--primary-400-base);
}

.cellSelect :global(.klassapp-dropdown__control) {
    background-color: transparent !important;
    box-shadow: none !important;
}

.cellSelect :global(.klassapp-dropdown__control .klassapp-dropdown__value-container) {
    padding: 2px 0;
}

.cellSelect :global(.klassapp-dropdown__control .klassapp-dropdown__placeholder),
.cellSelect :global(.klassapp-dropdown__control .klassapp-dropdown__single-value) {
    font-size: 13px;
    font-weight: 400 !important;
}

.addItemButton {
    margin-top: 12px;
}

.numberInput {
    width: 100%;
    min-width: 80px;
    padding: 4px 8px;
    /*border: 2px solid #e5e5e5;*/
    border-color: transparent;
    background-color: transparent !important;
    text-align: right;
}

.numberInput:focus-visible {
    outline: auto ridge var(--primary-400-base);
}

.numberInput:hover,
.numberInput:focus-within {
    background-color: rgba(0, 0, 0, 0.07) !important;
}

.numberValue {
    padding: 3px 16px;
    word-break: normal;
    text-align: right;
}

.dateInput {
    box-shadow: none;
    border: none !important;
    background: none;
    padding: 4px 0;
}

.dateInput:hover,
.dateInput:focus-within {
    background-color: rgba(0, 0, 0, 0.07) !important;
}

.dateInput :global(.ant-picker-suffix) {
    display: none;
}

.dateInput :global(.ant-picker-clear) {
    margin-right: 0 !important;
}

.error {
    border: 2px solid var(--error-400-base) !important;
}

.error :global(.klassapp-dropdown__control) {
    box-shadow: none;
}
